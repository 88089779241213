import React from 'react';
import './NotFoundPage.scss';
import NavBar from '../../components/navBar/NavBar';

const NotFoundPage = () => {
    return(
        <div className={'notFoundPage'}>
            <NavBar />
            <div className={'textContainer'}>
                <h3>Uh Oh!</h3> 
                <p>We can't seem to find what you are looking for.</p>
                <p>If you think this is an error, please contact support <a href='mailto:support@jackandaugust.com'>here</a></p>
            </div>
        </div>
    )
}

export default NotFoundPage;