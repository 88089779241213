import React from 'react';
import './Message.scss';
import { auth } from '../../data/firebaseConfig';

interface MessageProps {
    id: string;
    text: string;
    senderId: string;
}

const Message = (props: MessageProps) => {    
    return (
        <>
            {props.senderId === auth?.currentUser?.uid ?
            <div className={'messageContainer'}>
                <div className={'messageBubble'}>
                    <div className={'messageInfo'}>
                        <p>{props.text}</p>
                    </div>
                </div>
            </div>
            :
            <div className={'partnerMessageContainer'}>
                <div className={'partnerMessageBubble'}>
                    <div className={'messageInfo'}>
                        <p>{props.text}</p>
                    </div>
                </div>
            </div>}
        </>
    )
}

export default Message;