import React from 'react';
import './LoadingPage.scss';

const LoadingPage = () => {
    return(
        <div className={'loadingPage'}>
            <img className={'spinner'} src={require('./../../assets/name-and-logo-spinner.gif')} />
        </div>
    )
}

export default LoadingPage;