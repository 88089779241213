import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    user: {
        id: '',
        displayName: ''
    }
}

const userSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        setUser: (state, action)=> {
            state.user = action.payload
        },
        resetUser: (state, action) => {
            state.user = initialState.user
        }
    }
})

export const { setUser, resetUser } = userSlice.actions;
export default userSlice.reducer;