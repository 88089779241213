import React, { useEffect } from 'react';
import { Routes, Route, redirect } from 'react-router-dom';
import './App.scss';
import { onAuthStateChanged } from 'firebase/auth';
import LandingPage from './pages/landingPage/LandingPage';
import FoundChildDetailsPage from './pages/foundChildDetailsPage/FoundChildDetailsPage';
import NotFoundPage from './pages/notFoundPage/NotFoundPage';
import MessagePage from './pages/messagePage/MessagePage';
import ShopPage from './pages/shopPage/ShopPage';
import AboutUsPage from './pages/aboutUsPage/AboutUsPage';
import { auth } from './data/firebaseConfig';
import DownloadAppPage from './pages/downloadAppPage/DownloadAppPage';

function App() {
  useEffect(()=> {
    const unsub = onAuthStateChanged(auth, async (user) => {
      if(!user) {
      } else {
        try {
        } catch(err) {
          console.error(err)
        }
      }
    })
    return ()=> unsub();
  },[])

  return (
    <div className="App">
      <Routes>
        <Route path={'/not-found'} element={<NotFoundPage />}/>
        <Route path={'/chat/:chatId'} element={<MessagePage />}/>
        <Route path={'/clothes/:clothesId'} element={<FoundChildDetailsPage />}/>
        {/* <Route path={'/about'} element={<AboutUsPage />}/> */}
        {/* <Route path={'/download-app'} element={<DownloadAppPage />}/> */}
        {/* <Route path={'/shop'} element={<ShopPage />}/> */}
        {/* <Route path={'/'}  element={<LandingPage />}/> */}
        <Route path={'*'} element={<NotFoundPage />}/>
      </Routes>
    
    </div>
  );
}

export default App;
