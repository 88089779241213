import React from 'react';
import './NavBar.scss';
import IonIcon from '@reacticons/ionicons';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../header/Header';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

const NavBar = () => {
    const [state, setState] = React.useState({
        right: false,
      });

    const navigate = useNavigate();
    
    const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

    const list = (anchor: Anchor) => (
        <Box
          sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
          role="presentation"
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
        >
          <List>
              <ListItem >
                <ListItemButton>
                    <a href={'https://shop.jackandaugust.com'}>Home</a>
                </ListItemButton>
              </ListItem>
              {/* <ListItem >
                <ListItemButton>
                    <Link to={'/about'}>About</Link>
                </ListItemButton>
              </ListItem>
              <ListItem >
                <ListItemButton>
                    <Link to={'/download-app'}>Download App</Link>
                </ListItemButton>
              </ListItem>
              <ListItem >
                <ListItemButton>
                    <Link to={'/shop'}>Shop</Link>
                </ListItemButton>
              </ListItem> */}
          </List>
        </Box>
      );

    return(
        <div className={'navBarContainer'}>
            <div className={'logoContainer'}>
                <img onClick={()=> navigate('/')} className={'logo'} src={require('./../../assets/name.png')} />
            </div>
            <div className={'nameContainer'}>
                <img onClick={()=> navigate('/')} className={'name'} src={require('./../../assets/name.png')} />
            </div>
            <ul className={'optionsContainer'}>
                <a href={'https://shop.jackandaugust.com'}>Home</a>
                {/* <Link to={'/about'}>About</Link>
                <Link to={'/download-app'}>Download App</Link>
                <Link to={'/shop'}>Shop</Link> */}
            </ul>
            <div className={'mobileMenuContainer'}>
                {(['right'] as const).map((anchor) => (
                    <React.Fragment key={anchor}>
                    <Button onClick={toggleDrawer(anchor, true)}><IonIcon name={'menu'} size={'large'} className={'menuIcon'}/></Button>
                    <SwipeableDrawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        onOpen={toggleDrawer(anchor, true)}
                    >
                        {list(anchor)}
                    </SwipeableDrawer>
                    </React.Fragment>
                ))}
            </div>
        </div>
    )
}

export default NavBar;
