import React, { useEffect, useState } from 'react';
import { redirect, useNavigate, useParams } from 'react-router-dom';
import { doc, getDoc, DocumentData, updateDoc, serverTimestamp } from 'firebase/firestore';
import { signInAnonymously } from 'firebase/auth'
import { auth, db } from '../../data/firebaseConfig';
import './FoundChildDetailsPage.scss';
import LoadingPage from '../loadingPage/LoadingPage';
import NavBar from '../../components/navBar/NavBar';

const FoundChildDetailsPage = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [itemExists, setItemExists] = useState<boolean | null>(null);
    const [isRegistered, setIsRegistered] = useState<boolean>(true)
    const [clothingItem, setClothingItem] = useState<DocumentData | undefined>(undefined);
    const [parent, setParent] = useState<DocumentData | undefined>(undefined)

    const { clothesId } = useParams();
    const navigate = useNavigate();

    const anonymousSignIn = async () => {
        if(auth.currentUser) return
        try {
            await signInAnonymously(auth);
        } catch(err) {
            console.log('ERROR IN ANONYMOUS SIGN IN: ', err)
        }
    }

    const handleClickContact = async () => {
        await anonymousSignIn();
        if(!auth.currentUser) return
        if(!parent) return
        if(!parent.id) return
        const combinedId = auth.currentUser.uid > parent.id ? auth.currentUser.uid + parent.id : parent.id + auth.currentUser.uid;
        navigate(`/chat/${combinedId}`)
    }

    useEffect(()=> {
        setIsLoading(true);
        const getClothingItem = async () => {
            if(!clothesId) return setItemExists(false)
            try {
                const clothingItemref = doc(db, 'clothes', clothesId)
                const data = await getDoc(clothingItemref);
                const foundClothingItem = data.data();
                if(!foundClothingItem) {
                    setItemExists(false)
                } else {
                    setItemExists(true)
                }
                setIsRegistered(foundClothingItem?.isRegistered)
                if(!isRegistered) {
                    window.location.href = 'https://shop.jackandaugust.com/pages/download-app'
                }
                if(itemExists === false) {
                    window.location.href = 'https://shop.jackandaugust.com/not-found'
                }
                if(itemExists === true && isRegistered) {
                    await updateDoc(doc(db, 'clothes', clothesId), {
                        lastScanned: serverTimestamp()
                    })
                }
                setClothingItem(foundClothingItem)
            } catch(err) {
                console.log('ERROR GETTING CLOTHING ITEM: ', err)
            }
        }
        const timeout = setTimeout(()=> {
            getClothingItem();
            setIsLoading(false)
        }, 300)
        return ()=> clearTimeout(timeout);
    }, [isRegistered, itemExists, clothesId]);

    useEffect(()=> {
        const getParent = async () => {
            if(!clothingItem) return 
            try {
                const parentRef = doc(db, 'users', clothingItem.parentId)
                const data = await getDoc(parentRef);
                const foundParent = data.data();
                setParent({...foundParent, id: clothingItem.parentId});
            } catch(err) {
                console.log('ERROR GETTING PARENT INFO: ', err)
            }
        }
        getParent(); 
        // setIsLoading(false) 
    }, [clothingItem])

    if (isLoading || itemExists === null) {
        return <LoadingPage />;
    }

    if (itemExists === false) {
        return null; 
    }

    if(!isRegistered) {
        return null;
    }

    return (
        <div className={'foundChildDetailsPage'}>
            <NavBar />
            <div className={'pageWrapper'}>
                <div className={'headerContainer'}>
                    <p className={'headerText'}>Did You Find A Lost Child?</p>
                </div>
                <div className={'contentContainer'}>
                    <div className={'contactInfoContainer'}>
                        <p>You just scanned a Jack & August QR Clothing Label. These QR Codes are designed to help lost children find their parent, with some help from you. <a className={'learnMore'} href={'https://shop.jackandaugust.com'}>[Learn More]</a></p> 
                        <p>The child's parent has been notified that you just scanned this code and is waiting to hear from you. Please help them find their child by contacting them through a secure and private chat.</p>
                        <button className={'primaryButton'} onClick={handleClickContact}>Contact Parent</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FoundChildDetailsPage;