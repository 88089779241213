import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    parentInfo: {
        id: '',
        name: {
            firstName: '',
            lastName: '',
        },
    }
}

const parentSlice = createSlice({
    name: 'parent',
    initialState: initialState,
    reducers: {
        setParentInfo: (state, action)=> {
            state.parentInfo = action.payload
        },
        resetParentInfo: (state) => {
            state.parentInfo = initialState.parentInfo
        }
    }
})

export const { setParentInfo, resetParentInfo } = parentSlice.actions;
export default parentSlice.reducer;