import React, { useState, useEffect, useRef } from 'react';
import './MessagePage.scss';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IonIcon from '@reacticons/ionicons';
import Message from '../../components/message/Message';
import { useNavigate, useParams } from 'react-router';
import { doc, setDoc, getDoc, onSnapshot, updateDoc, arrayUnion, serverTimestamp } from 'firebase/firestore';
import { auth, db } from '../../data/firebaseConfig';
import { v4 as uuid } from 'uuid'
import LoadingPage from '../loadingPage/LoadingPage';

interface Error {
    message: string;
}

const MessagePage = () => {
    const [messages, setMessages] = useState<any[]>([]);
    const [newMessage, setNewMessage] = useState<string>('');
    const [nameInput, setNameInput] = useState<string>('');
    const [user, setUser] = useState<any>(null);
    const [isModalOpen, setisModalOpen] = useState<boolean>(false);
    const [error, setError] = useState<Error | null>(null);
    const [parentInfo, setParentInfo] = useState<any>();
    const[displayChat, setDisplayChat] = useState<boolean>(false);
    const { chatId } = useParams();

    const messagesEndRef = useRef<null | HTMLDivElement>(null)
    const navigate = useNavigate();

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }

    const handleSubmitMessage = async (e: React.FormEvent) => {
        e.preventDefault();
        if(!chatId) return
        if(newMessage === '') return
        try {
            await updateDoc(doc(db, 'messages', chatId), {
                messages: arrayUnion({
                    id: uuid(),
                    text: newMessage,
                    senderId: user.id
                })
            })
            setNewMessage('');

            await updateDoc(doc(db, 'users', parentInfo.id, 'chats', chatId), {
                lastMessage: {
                    text: newMessage, 
                    timeSent: serverTimestamp()
                },
                unreadMessages: true
            })
        } catch(err: any) {
            console.error(err)
            if(err.code === 'not-found') {
                setError({message: `Your message was not sent. ${parentInfo.name.firstName} has deleted the chat`});
            } else {
                setError({message: 'An unknown error has occured'})
            }
        }
    }

    const handleStartChat = async () => {
        if(!auth.currentUser) return
        if(!chatId) return
        try {
            //create chatUser
            await setDoc(doc(db, 'chatUsers', auth.currentUser?.uid), {
                name: nameInput
            })
            setUser({id: auth.currentUser.uid, name: nameInput});
            //create messages with combinedId
            await setDoc(doc(db, 'messages', chatId), {messages:[]});

            //create a chat for the user and parent
            const myNewChat = {
                lastMessage: {
                    text: '',
                    timeSent: serverTimestamp(),
                },
                partnerInfo: {
                    id: parentInfo.id,
                    name: {
                        firstName: parentInfo.name.firstName,
                        lastName: parentInfo.name.lastName
                    }
                }
            }

            const partnerNewChat= {
                lastMessage: {
                    text: '',
                    timeSent: serverTimestamp(),
                },
                partnerInfo: {
                    id: auth.currentUser.uid,
                    name: {
                        firstName: nameInput,
                    }
                }
            }
            // await setDoc(doc(db, 'chatUsers', newUser.id, 'chats', combinedId), myNewChat);
            await setDoc(doc(db, 'users', parentInfo.id, 'chats', chatId), partnerNewChat);
            setisModalOpen(false)
        } catch(err) {
        console.error(err)       
        }
    }


    useEffect(()=> {
        if(!chatId) return
        const unsub = onSnapshot(doc(db, 'messages', chatId), (doc)=> {
            if(!doc.exists()) {
                setisModalOpen(true)
            } else {
                setMessages(doc.data().messages)
            }
        })
        return ()=> unsub();
    }, [chatId])

    useEffect(()=> {
        scrollToBottom();
    }, [messages])

    useEffect(()=> {
        const getParentInfo = async () => {
            try {
                if(!chatId) return
                if(!auth.currentUser?.uid) return
                const parentId = chatId.replace(auth.currentUser.uid, '')
                const userRef = doc(db, 'users', parentId);
                const parentData = await getDoc(userRef)
                const foundParent = parentData.data();
                setParentInfo({...foundParent, id: parentId})
            } catch(err) {
                console.error(err)
            }
        }
        getParentInfo(); 
    }, [chatId, auth.currentUser])

    useEffect(()=> {
        if(!auth.currentUser) {
             setDisplayChat(false);
             return
        } 
        if(!chatId) {
            setDisplayChat(false);
            navigate('/not-found');
            return
        } 
        if(!chatId.includes(auth.currentUser.uid)) {
            setDisplayChat(false);
            navigate('/not-found');
            return
        } 
        if(!parentInfo?.id) {
            setDisplayChat(false);
            return
        } 
        setDisplayChat(true)
        setUser({id: auth.currentUser.uid})
    },[chatId, parentInfo, auth.currentUser])

    return (
        <>
        {!displayChat ? <LoadingPage /> :
        <div className={'messagePage'}>
            <div className={'messagePageContainer'}>
                <div className={'navBar'}>
                    <div className={'empty'} />
                    <h3>{parentInfo?.name?.firstName}</h3>
                    <div className={'empty'}/>
                </div>
                <div className={'messagesContainer'}>
                    {messages.map(message => {
                        return <Message key={message.id} id={message.id} text={message.text} senderId={message.senderId} />
                    })}
                    <div ref={messagesEndRef} />
                    {error && <div className={'error'}>{error.message}</div>}
                </div>
            </div>
            <form className={'inputContainer'} onSubmit={handleSubmitMessage}>
                <input 
                    value={newMessage}
                    onChange={(e)=>setNewMessage(e.target.value)}
                    type={'text'}
                    placeholder={'New Message'}
                />
                <IonIcon name={'arrow-up-circle'} onClick={handleSubmitMessage}/>
            </form>

            <Dialog open={isModalOpen}>
                <DialogTitle>Enter Display Name</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please enter a display name in order to send a message to the child's parent.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Name"
                        type={'text'}
                        fullWidth
                        variant="standard"
                        value={nameInput}
                        onChange={(e)=>setNameInput(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button disabled={nameInput === ''} onClick={handleStartChat}>Start Chat</Button>
                </DialogActions>
            </Dialog>
        </div>}
        </>
    )
}

export default MessagePage;